import { Component, EventEmitter, input, Output } from "@angular/core";
import { DatalistTemplate } from "../../../record/core/domain/datalistTemplate/datalist-template";

@Component({
    selector: "mcase-data-list-cards",
    templateUrl: "./data-list-cards.component.html",
    styleUrl: "./data-list-cards.component.scss",
})
export class DataListCardsComponent {
    dataListTemplates = input.required<DatalistTemplate[]>();

    @Output() selected: EventEmitter<DatalistTemplate> =
        new EventEmitter<DatalistTemplate>();
}
