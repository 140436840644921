import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataListCardComponent } from "./presentation/data-list-card/data-list-card.component";
import { DataListCardsComponent } from "./presentation/data-list-cards/data-list-cards.component";
import { UserIconComponent } from "./presentation/user-icon/user-icon.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoadingSpinnerComponent } from "./presentation/loading-spinner/loading-spinner.component";

@NgModule({
    declarations: [
        DataListCardsComponent,
        DataListCardComponent,
        UserIconComponent,
        LoadingSpinnerComponent,
    ],
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [
        DataListCardsComponent,
        DataListCardComponent,
        UserIconComponent,
        LoadingSpinnerComponent,
    ],
})
export class SharedModule {}
