import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    input,
} from "@angular/core";
import { User } from "../../../login/core/domain/user.model";

@Component({
    selector: "mCase-user-icon",
    templateUrl: "./user-icon.component.html",
    styleUrl: "./user-icon.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconComponent implements OnInit {
    user = input.required<User>();
    userInitials!: string;

    ngOnInit(): void {
        const user = this.user();
        this.userInitials = user.firstName.charAt(0) + user.lastName.charAt(0);
    }
}
