import { Component, EventEmitter, input, Output } from "@angular/core";
import { DatalistTemplate } from "../../../record/core/domain/datalistTemplate/datalist-template";

@Component({
    selector: "mcase-data-list-card",
    templateUrl: "./data-list-card.component.html",
    styleUrl: "./data-list-card.component.scss",
})
export class DataListCardComponent {
    dataListTemplate = input.required<DatalistTemplate>();

    @Output() selected: EventEmitter<DatalistTemplate> =
        new EventEmitter<DatalistTemplate>();
}
