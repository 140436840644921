<div
    class="d-flex flex-column align-items-center justify-content-stretch gap-2 text-center"
>
    @let template = dataListTemplate();
    @let iconCharacter = dataListTemplate().optionTemplate.iconCharacter;

    <div
        class="circle-icon mat-elevation-z3"
        [style.background-color]="iconCharacter.backgroundColor"
        (click)="selected.emit(template)"
    >
        <i
            [class]="iconCharacter.id"
            [style.color]="iconCharacter.color"
            data-testid="testIcon"
        ></i>
    </div>
    <div
        class="circle-card-name mat-label-large"
        (click)="selected.emit(template)"
    >
        {{ template.name }}
    </div>
</div>
