@if (user().userPicture64bit) {
    <img
        data-testid="image-icon"
        [src]="user().userPicture64bit"
        alt="profile-icon"
    />
} @else {
    <div data-testid="initials-icon" class="default-icon">
        {{ userInitials }}
    </div>
}
